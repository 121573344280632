'use strict';

// postcss stylesheets
import './../styles/_tailwind.pcss'
import './../styles/main.pcss'

import global from './_global';
import animations from './_animations';
import {sliders} from "./_sliders";

const lazyLoading = () => import('./_lazy-load')

document.addEventListener("DOMContentLoaded", () => {
    global.pageLoadedChecker();
    global.getScreenSize();
    animations.accordions();
    animations.ShowHideElements();
    sliders();
})

document.addEventListener('everyThingIsLoaded', () => {
    lazyLoading().then((module) => module.init())
})