const
    glide = () => import('./_glide'),
    isHomePage = document.querySelector('.main-home'),
    isProductPage = document.querySelector('.single-product')

export const sliders = () => {
    if (isHomePage) {
        const
            productsSlider = document.querySelectorAll('.products-slider'),
            categoriesSlider = document.querySelectorAll('.categories-slider')


        if (productsSlider.length > 0) {
            const sliderSettings = {
                type: 'carousel',
                startAt: 0,
                perView: 4,
                gap: 15,
                autoplay: 3000,
                hoverpause: true,
                breakpoints: {
                    767: {
                        perView: 2,
                        gap: 10
                    }
                }
            }

            for (const slider of productsSlider) {
                glide().then(module => module.glideSlider(slider, sliderSettings).mount())
            }
        }

        if (categoriesSlider.length > 0) {
            const sliderSettings = {
                type: 'carousel',
                startAt: 0,
                perView: 1,
                gap: 15,
                autoplay: 2500
            }

            for (const slider of categoriesSlider) {
                glide().then(module => module.glideSlider(slider, sliderSettings).mount())
            }
        }

    }

    if (isProductPage) {
        const
            productsSlider = document.querySelectorAll('.products-slider')

        if (productsSlider.length > 0) {
            const sliderSettings = {
                type: 'carousel',
                startAt: 0,
                perView: 4,
                gap: 15,
                autoplay: 3000,
                hoverpause: true,
                breakpoints: {
                    767: {
                        perView: 2,
                        gap: 10
                    }
                }
            }

            for (const slider of productsSlider) {
                glide().then(module => module.glideSlider(slider, sliderSettings).mount())
            }
        }


    }
}