import {DOM, platform, globals} from './_variables';

export default {
    pageLoadedChecker: () => {
        if (platform.mobile.iOS) {
            DOM.html.classList.add('iOS');
        }

        if (platform.mobile.android) {
            DOM.html.classList.add('android');
        }

        if (globals.hasTouchScreen()) {
            DOM.html.classList.add('touch')
        }

        let everythingLoaded = setInterval(() => {
            const loadedEvent = new Event('everyThingIsLoaded')

            if (/loaded|complete/.test(document.readyState)) {
                clearInterval(everythingLoaded);

                globals.isLoaded = true;

                document.dispatchEvent(loadedEvent)
            }
        }, 10);
    },
    getScreenSize: () => {
        const breakpoint = window.matchMedia("(max-width: 767px)");

        const checkResolution = () => {
            if (breakpoint.matches) {
                DOM.html.classList.add('screen-sm');

                if (DOM.html.classList.contains('screen-lg')) {
                    DOM.html.classList.remove('screen-lg');
                }
            } else {
                document.dispatchEvent(new CustomEvent('screen-lg'));

                if (DOM.html.classList.contains('screen-sm')) {
                    DOM.html.classList.remove('screen-sm');
                }
            }

            requestAnimationFrame(checkResolution)
        }

        checkResolution();
    }
}
