import {DOM} from './_variables';

const
    accordionAnimation = () => import('./_accordions')

export default {
    accordions: () => {
        const
            accordions = DOM.html.querySelectorAll('.accordion')

        if (accordions.length > 0) {
            for (const accordion of accordions) {
                const
                    title = accordion.querySelector('.accordion__title'),
                    collapsible = accordion.querySelector('.accordion__collapsible'),
                    openCallBack = () => {
                        title.classList.add('accordion__title--active')
                        collapsible.classList.add('accordion__collapsible--active')
                    },
                    closeCallBack = () => {
                        title.classList.remove('accordion__title--active')
                        collapsible.classList.remove('accordion__collapsible--active')
                    }

                let isOpen = false

                title.addEventListener('click', () => {
                    if (!isOpen) {
                        accordionAnimation().then((module) => module.open(collapsible, openCallBack))
                    } else {
                        accordionAnimation().then((module) => module.close(collapsible, closeCallBack, 0))
                    }

                    isOpen = !isOpen
                })
            }
        }
    },

    ShowHideElements: () => {
        (function (window, $) {

            $('.ham').click(function () {
                $(this).toggleClass('open');
                $('.burger-menu').toggleClass('open');
            });

            $('.menu-shop').mouseenter(function () {
                $('.shop-menu-header').addClass('open');
            });

            $('.shop-menu-header').mouseleave(function() {
                $(this).removeClass('open');
            });

        })(window, jQuery);
    }
}
